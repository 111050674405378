<template>
  <PageLoader outlined v-if="fetching" min-height="100vh"></PageLoader>
  <v-card
    elevation="3"
    min-height="100vh"
    class="profile-page"
    v-else-if="view_user"
  >
    <v-card-title class="primary profile-wrapper">
      <v-menu bottom left v-if="can_update_profile || can_change_password">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon fab v-bind="attrs" class="settings" v-on="on">
            <v-icon color="white">mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="can_update_profile"
            @click="action_edit_dialog(true, view_user)"
          >
            <v-list-item-icon class="mr-3">
              <v-icon color="primary">mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text">
              Update Profile
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="can_change_password" @click="updatePassword">
            <v-list-item-icon class="mr-3">
              <v-icon color="primary">mdi-lock-open-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text">
              Update Password
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-row dense class="relative-block profile-card">
        <div
          class="profile-pic"
          :style="`background-image: url(${view_user.image_url});`"
        >
          <v-hover v-slot:default="{ hover }" v-if="can_update_profile">
            <v-avatar
              @click="image_uploader_dialog = true"
              class="cursor-pointer"
              :color="hover ? 'primary' : 'white'"
              size="40"
            >
              <v-icon :color="hover ? 'white' : 'primary'"> mdi-camera </v-icon>
            </v-avatar>
          </v-hover>
        </div>
        <v-row dense class="profile-name-container">
          <v-col :cols="mdAndUp ? 4 : 12">
            <template v-if="!view_user_is_client">
              <div class="primary--text user-name">
                {{ rate | money }}
              </div>
              <div class="primary--text user-desc">Rate|Hour</div>
            </template>
          </v-col>
          <v-col :cols="mdAndUp ? 4 : 12">
            <div class="primary--text font-weight-bold user-name">
              {{ view_user.fullname | ucwords }}
            </div>
            <div class="primary--text user-desc">
              {{ view_user.job_title | ucwords }}
            </div>
          </v-col>
          <v-col :cols="mdAndUp ? 4 : 12">
            <template v-if="!view_user_is_client">
              <div class="primary--text user-name">
                {{ location | ucwords }}
              </div>
              <div class="primary--text user-desc">Location</div>
            </template>
          </v-col>
        </v-row>
      </v-row>
    </v-card-title>
    <v-sheet>
      <v-tabs
        class="tabs"
        center-active
        grow
        :show-arrow="true"
        v-model="selectionTab"
      >
        <v-tab active-class="primary light--text" centered href="#profile">
          <span class="label fs-15">Affiliation</span>
        </v-tab>
        <v-tab
          active-class="primary light--text"
          centered
          href="#profile-invoices"
        >
          <span class="label fs-15">Invoices</span>
        </v-tab>
        <v-tab
          active-class="primary light--text"
          centered
          href="#profile-projects"
        >
          <span class="label fs-15">Projects</span>
        </v-tab>
        <v-tab
          active-class="primary light--text"
          centered
          v-if="!view_user_is_client"
          href="#profile-tasks"
        >
          <span class="label fs-15">Tasks</span>
        </v-tab>
      </v-tabs>
    </v-sheet>
    <v-divider />
    <v-card-text class="whitesmoke rounded-10 profile-content-wrapper">
      <router-view :key="$route.fullPath" />
    </v-card-text>

    <ImageCropper
      v-model="image_uploader_dialog"
      @file="handleFileUpload"
      value-format="attachment"
    ></ImageCropper>

    <TeamsDialog
      v-model="edit_dialog"
      :item="activeItem"
      :has-role-change="false"
      @update="handleUpdateUser"
      @add-new-group="add_dialog = true"
    ></TeamsDialog>

    <PasswordChangerDialog
      v-model="change_password_dialog"
      :item="activeItem"
      @save="handleUpdatePassword"
    ></PasswordChangerDialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ImageCropper from '@/common/Dialogs/ImageCropper.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
import TeamsDialog from '@/modules/Teams/components/TeamsDialog/TeamsDialog.vue'
import PasswordChangerDialog from './components/PasswordChangerDialog.vue'
export default {
  name: 'Profile',
  mixins: [fetch_services],
  components: {
    ImageCropper,
    TeamsDialog,
    PasswordChangerDialog
  },
  data: () => ({
    image_uploader_dialog: false,
    change_password_dialog: false,
    roles: []
  }),
  computed: {
    ...mapGetters(['user', 'user_roles']),
    ...mapGetters('memberProfile', [
      'view_user',
      'view_user_roles',
      'rate',
      'location'
    ]),
    ...mapGetters('memberProfile', { fetching: 'loading' }),
    view_user_is_client() {
      return this.$store.getters['memberProfile/view_user_has_role']('client')
    },
    can_update_profile() {
      if (this.user.is_owner || this.user.is_super_admin) return true
      if (this.user.id === this.view_user.id) return true
      if (this.user.is_admin && this.view_user.is_admin) return false
      if (this.user.is_admin) return true
      if (
        this.user.is_manager &&
        (this.view_user.is_member || this.view_user.is_client)
      )
        return true
      return false
    },
    can_change_password() {
      if (this.user.is_owner || this.user.is_super_admin) return true
      if (this.user.id === this.view_user.id) return true
      if (this.user.is_admin && this.view_user.is_admin) return false
      if (this.user.is_admin) return true
      if (
        this.user.is_manager &&
        (this.view_user.is_member || this.view_user.is_client)
      )
        return true
      return false
    },
    selectionTab: {
      get() {
        return this.$store.getters['memberProfile/selectionTab']
      },
      set(val) {
        this.set_selectionTab(val)
      }
    }
  },
  watch: {
    selectionTab(val) {
      this.$router.push({ name: val }).catch(() => {})
    },
    '$route.params.username': {
      handler: function (new_val) {
        if (new_val) this.fetchUser(new_val)
      },
      immediate: true
    },
    '$route.name': {
      handler: function (val) {
        if (val) this.set_selectionTab(val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions('memberProfile', ['fetchUser']),
    ...mapMutations('memberProfile', ['set_selectionTab']),
    is_active(tab) {
      return tab.toLowerCase() === this.tab.toLowerCase()
    },
    handleFileUpload(attachment) {
      this.$store.dispatch('memberProfile/changeUserAvatar', {
        attachment: attachment,
        cb: (data) => {
          this.appSnackbar('User avatar updated')
          this.image_uploader_dialog = false
          if (this.view_user.id === this.user.id) {
            this.$store.commit('set_user_image', data.image_url)
          }
        }
      })
    },
    load_roles() {
      request.get(`api/roles/company`).then(({ data }) => {
        this.roles = _.cloneDeep(data).filter((i) => !i.slug.includes('client'))
      })
    },
    updatePassword() {
      this.activeItem = _.cloneDeep(this.view_user)
      this.change_password_dialog = true
    },
    handleUpdateUser(payload) {
      this.$store.dispatch('memberProfile/updateTeamUser', {
        userId: this.view_user.id,
        payload: payload,
        cb: (data) => {
          this.action_edit_dialog(false, null)
          if (this.view_user.id === this.user.id) {
            this.$store.commit('set_login_user', data)
          }
          this.fetchUser(this.view_user.username)
          this.appSnackbar('User profile updated')
        },
        err_cb: () => {
          this.$event.$emit('btnloading_off')
        }
      })
    },
    handleUpdatePassword(payload) {
      this.$store.dispatch('memberProfile/changeUserPassword', {
        payload: payload,
        cb: (data) => {
          this.change_password_dialog = false
          this.appSnackbar('User password updated')
        },
        err_cb: () => {
          this.$event.$emit('btnloading_off')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./Profile.scss"></style>
