<template>
  <CustomDialog
    title="Change Password"
    :open.sync="open"
    button2-text="Save"
    @button2="save"
     
    @button1="cancel"
    :main-btn-disabled="!valid"
  >
    <template slot="content">
      <v-card flat>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" v-if="required_current_password">
              <label class="subtitle-2 primary--text"> Current password </label>
              <v-text-field
                dense
                outlined
                :rules="requiredRules"
                prepend-inner-icon="mdi-lock"
                hide-details="auto"
                :disabled="disabled_all"
                placeholder=" "
                persistent-placeholder
                :append-icon="
                  show_current_password ? 'visibility_off' : 'visibility'
                "
                :type="show_current_password ? 'text' : 'password'"
                v-model="current_password"
                @click:append="show_current_password = !show_current_password"
              />
            </v-col>

            <v-col cols="12">
              <label class="subtitle-2 primary--text"> New Password </label>
              <v-text-field
                dense
                outlined
                :rules="[minLengthRule(6)]"
                placeholder=" "
                persistent-placeholder
                prepend-inner-icon="mdi-onepassword"
                hide-details="auto"
                :disabled="disabled_all"
                :append-icon="show_password ? 'visibility_off' : 'visibility'"
                :type="show_password ? 'text' : 'password'"
                v-model="password"
                @click:append="show_password = !show_password"
              />
            </v-col>

            <v-col cols="12">
              <label class="subtitle-2 primary--text">
                Confirm New Password
              </label>
              <v-text-field
                dense
                :rules="[passwordConfirmationRule(password)]"
                outlined
                placeholder=" "
                persistent-placeholder
                prepend-inner-icon="mdi-onepassword"
                hide-details="auto"
                :disabled="disabled_all"
                :append-icon="
                  show_repeat_password ? 'visibility_off' : 'visibility'
                "
                :type="show_repeat_password ? 'text' : 'password'"
                v-model="repeat_password"
                @click:append="show_repeat_password = !show_repeat_password"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import request from "@/services/axios_instance";
//components
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";

export default {
  name: "PasswordChangerDialog",
  props: {
    item: { type: Object },
    value: { type: Boolean, default: false },
  },
  watch: {
    value(val) {
      this.open = val;
      if (val) this.reset();
    },
    open(val) {
      this.$emit("input", val);
    },
    user: {
      handler: function (val) {
        this.user = val ? _.cloneDeep(val) : null;
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    CustomDialog,
  },

  data: () => ({
    open: false,
    user: null,
    current_password: "",
    password: "",
    repeat_password: "",
    show_current_password: false,
    show_password: false,
    show_repeat_password: false,
  }),
  computed: {
    initiator() {
      return this.$store.getters.user;
    },
    required_current_password() {
      return !this.initiator.is_admin;
    },
    disabled_all() {
      return this.user &&
        !this.initiator.is_admin &&
        this.initiator.id !== this.user.id
        ? true
        : false;
    },
    payload() {
      let fields = {
        user_id: this.item ? this.item.id : null,
        password: this.password,
        password_confirmation: this.repeat_password,
        required_current_password: this.required_current_password,
      };
      if (this.required_current_password) {
        fields.current_password = this.current_password;
      }
      return fields;
    },
    valid() {
      if (this.required_current_password && !this.payload.current_password) {
        return false;
      }
      return this.payload.user_id > 0 &&
        this.payload.password &&
        this.payload.password_confirmation
        ? true
        : false;
    },
  },

  methods: {
    cancel() {
      this.open = false;
    },
    reset() {
      this.password = this.repeat_password = this.current_password = null;
    },
    save() {
      if (this.valid) {
        this.$emit("save", this.payload);
      }
    },
  },
};
</script> 
<style scoped>
</style>
